import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { UIModule } from '@bannerflow/ui';
import { SharedModule } from 'src/app/shared/shared.module';
import { PasswordRequirementsComponent } from 'src/app/shared/components';

import { RegistrationComponent } from './components/registration/registration.component';
import { RegistrationService } from './services';

@NgModule({
    imports: [
        PasswordRequirementsComponent,
        CommonModule,
        RouterModule,
        UIModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        BrowserAnimationsModule,
        RegistrationComponent
    ],
    exports: [RegistrationComponent],
    providers: [RegistrationService]
})
export class RegistrationModule {}
