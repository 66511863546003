<div class="content">
    <title>Something went wrong</title>
    <div class="logo">
        <ui-logo [small]="true"></ui-logo>
    </div>
    <div class="title">
        <h1>Something went wrong</h1>
    </div>
    <div class="text">
        <p>It seems like something didn't go as expected.</p>
        <p class="second-ph">
            If you need any assistance,<br />please contact
            <a href="mailto:support@bannerflow.com">support&#64;bannerflow.com</a>
        </p>
        <p class="third-ph">The Bannerflow Team</p>
    </div>
</div>
