import { inject, Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { ApiService, BFHttpError } from 'src/app/shared/services';

import { NewUser, UserRegistrationData } from '../models';
import { ActivatedRoute } from '@angular/router';

@Injectable()
export class RegistrationService {
    private apiService: ApiService = inject(ApiService);
    private route: ActivatedRoute = inject(ActivatedRoute);

    public async getUserRegistrationForm(): Promise<UserRegistrationData> {
        const userId: string = this.route.snapshot.queryParams.userId;
        const invitationId: string = this.route.snapshot.queryParams.invitationId;
        const queryParams = new URLSearchParams([
            ['userId', userId],
            ['invitationId', invitationId]
        ]);

        const result = await this.apiService.get<UserRegistrationData>(
            `${environment.BAU_SERVICE_URL}/api/users/invite?${queryParams.toString()}`,
            { errorNotification: false }
        );

        if (result instanceof BFHttpError) return Promise.reject(result);

        return result;
    }

    public async finishRegistration(user: NewUser): Promise<string> {
        const result = await this.apiService.post<string>(
            `${environment.BAU_SERVICE_URL}/api/users/register`,
            user,
            {
                errorNotification: true
            }
        );

        if (result instanceof BFHttpError) return Promise.reject(result);

        return result;
    }

    public redirectToBannerflowClient(): void {
        location.href = environment.BANNEFLOW_CLIENT_URL;
    }
}
