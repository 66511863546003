import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UIModule } from '@bannerflow/ui';

import { ApiService, ProfileService, TimeZoneService } from './services';

@NgModule({
    imports: [CommonModule, FormsModule, UIModule],
    exports: [UIModule],
    providers: [ApiService, ProfileService, TimeZoneService]
})
export class SharedModule {}
