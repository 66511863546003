export interface Environment {
    LOGIN_URL: string;
    CLIENT_ID: string;
    BANNEFLOW_CLIENT_URL: string;
    ACCOUNT_ACCESS_SERVICE_URL: string;
    BAU_SERVICE_URL: string;
    CONNECTION_HINT_MAP: Map<string, string>;
}

export const PasswordConnectionHint = 'pw';
