import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

import { UIModule } from '@bannerflow/ui';

import { PasswordRequirements, PasswordRequirementsChecklist } from '../../models';

@Component({
    selector: 'app-password-requirements',
    templateUrl: './password-requirements.component.html',
    styleUrls: ['./password-requirements.component.scss'],
    imports: [CommonModule, UIModule]
})
export class PasswordRequirementsComponent {
    @Input() public checklist: PasswordRequirementsChecklist;
    @Input() public passwordRequirements: PasswordRequirements;
}
