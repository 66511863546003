import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { NgIf } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { UIModule } from '@bannerflow/ui';
import { Subscription } from 'rxjs';

import { LoginService } from '../../services';

@Component({
    selector: 'app-login-test',
    templateUrl: './login-test.component.html',
    styleUrls: ['./login-test.component.scss'],
    imports: [NgIf, UIModule]
})
export class LoginTestComponent implements OnInit, OnDestroy {
    private activatedRoute: ActivatedRoute = inject(ActivatedRoute);
    private loginService: LoginService = inject(LoginService);
    private router: Router = inject(Router);

    private subscription: Subscription;

    public success = false;

    ngOnInit(): void {
        this.getAccessToken();
    }

    getAccessToken() {
        const connectionHint: string = this.activatedRoute.snapshot.queryParams.ch ?? '';
        this.subscription = this.loginService.getTokenWithConnectionHint(connectionHint).subscribe({
            next: token => {
                this.success = !!token;
            },
            error: () => {
                this.router.navigate(['/oops']);
            }
        });
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
