// libs
import { inject, Injectable } from '@angular/core';

// app
import {
    UIErrorDialogService,
    IBFError,
    IBFHttpError,
    IUIErrorDialogConfig,
    UINotificationService
} from '@bannerflow/ui';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

type AllowedMethods = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE' | 'FETCH';

/**
 * Service for making HTTP requests.
 * Also handles errors for these requests in a unified way.
 */
export interface IApiOptions {
    cache?: boolean;
    /**
     * If set to true, shows a red notification sign if error occurs. 500 errors will always trigger error dialog.
     */
    errorNotification?: boolean;
    /**
     * If set to true, removes the /api/v2/account/brand prefix in url
     */
    anonymous?: boolean;

    /**
     * Object with parameters that should be added on the url { value: 1, data: 'hi'} => 'value=1&data=hi'
     */
    queryParameters?: object;

    /**
     * If set to true this will return the entire response not just the body
     */
    fullResponse?: boolean;
}

@Injectable()
export class ApiService {
    private http: HttpClient = inject(HttpClient);
    private errorDialogService: UIErrorDialogService = inject(UIErrorDialogService);
    private notificationService: UINotificationService = inject(UINotificationService);

    public get<T>(url: string, options?: IApiOptions): Promise<T | BFHttpError> {
        return this.makeRequest(url, 'GET', null, options);
    }

    public fetch<T>(url: string, options?: IApiOptions): Promise<T | BFHttpError> {
        return this.makeRequest(url, 'FETCH', null, options);
    }

    public post<T>(url: string, data: unknown, options?: IApiOptions): Promise<T | BFHttpError> {
        return this.makeRequest(url, 'POST', data, options);
    }

    public put<T>(url: string, data: unknown, options?: IApiOptions): Promise<T | BFHttpError> {
        return this.makeRequest(url, 'PUT', data, options);
    }

    public patch<T>(url: string, data: unknown, options?: IApiOptions): Promise<T | BFHttpError> {
        return this.makeRequest(url, 'PATCH', data, options);
    }

    public delete<T>(url: string, options?: IApiOptions): Promise<T | BFHttpError> {
        return this.makeRequest(url, 'DELETE', null, options);
    }

    /**
     * Convert object to query paremeters. { color: 'green', size: 46 } => 'color=green&size=46'
     * @param obj
     */
    public toQueryString(obj?: object): string {
        const parts: string[] = [];
        for (const [k, v] of Object.entries(obj)) {
            if (typeof v !== 'undefined') {
                parts.push(encodeURIComponent(k) + '=' + encodeURIComponent(v));
            }
        }

        return parts.join('&');
    }

    private async makeRequest<T>(
        url: string,
        method: AllowedMethods,
        body: unknown,
        options: IApiOptions = {}
    ): Promise<T | BFHttpError> {
        const headers: HttpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
        const requestUrl: string = this.getRequestUrl(url, options);

        return this.http
            .request<T>(method, requestUrl, { headers, body })
            .toPromise()
            .then(res => {
                if (options.fullResponse) {
                    return res;
                }

                return this.extractData<T>(res as T);
            })
            .catch((response: HttpErrorResponse) => {
                return this.handleError(response, options.errorNotification);
            });
    }

    private getRequestUrl(url: string, options: IApiOptions = {}): string {
        let requestUrl: string = url;

        if (options.queryParameters) {
            requestUrl =
                requestUrl +
                (requestUrl.indexOf('?') === -1 ? '?' : '&') +
                this.toQueryString(options.queryParameters);
        }

        return requestUrl;
    }

    private async handleError(
        error?: HttpErrorResponse,
        showNotificationOnHandledError?: boolean,
        jsonError?: boolean
    ): Promise<BFHttpError> {
        const bfHttpError: BFHttpError = new BFHttpError(error);

        if (jsonError) {
            bfHttpError.message = 'Could not parse json string';
        }

        // Show dialog for unhandled errors
        if (bfHttpError.status === 500 || jsonError) {
            // Here it is possible to add title to the Error dialog
            const config: IUIErrorDialogConfig = {};

            await this.errorDialogService.show(config, bfHttpError);
        } else if (showNotificationOnHandledError) {
            this.generateErrorMessage(bfHttpError.message, 5000);
        }

        return Promise.reject(bfHttpError);
    }

    private generateErrorMessage(message: string, autoCloseDelay: number): void {
        const msg: string = message ? message : 'Something went wrong!';

        this.notificationService.open(msg, {
            type: 'error',
            placement: 'top',
            autoCloseDelay,
            icon: 'alert'
        });
    }

    private extractData<T>(res: T): T {
        let responseData: T;

        try {
            responseData = res;
        } catch {
            this.handleError(undefined, false, true);
        }

        return responseData;
    }
}

/**
 * Draft on a generic error handling... (Maybe better as an interface?)
 */
export class BFError implements IBFError {
    public title = '';
    public message = '';
    public code!: number;

    constructor(params: Partial<BFError> = {}) {
        Object.assign(this, params);
    }
}

/**
 * Error returned by API service when
 * an HTTP request was unsuccessful (status not equal 200)
 */
export class BFHttpError extends BFError implements IBFHttpError {
    public requestId!: string;
    public requestUrl!: string;
    public status!: number;

    /**
     * Creates a new instance of the BFHttpError class.
     * @param message The error message returned by the server.
     * @param status The HTTP status code.
     */
    constructor(response?: HttpErrorResponse) {
        super();

        if (response) {
            this.message = response.error.message;
            this.code = response.error.code;
            this.title = response.statusText;
            this.status = response.status;
            this.requestUrl = response.url!;

            if (response.headers) {
                this.requestId = response.headers.get('bannerflow-request-id')!;
            }
        }
    }
}
