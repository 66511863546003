<div
    class="row"
    *ngIf="checklist.hasOwnProperty('minimumCharacters')">
    <ui-checkbox
        [disabled]="true"
        [selected]="checklist.minimumCharacters"
        label="At least {{ passwordRequirements.minimumCharacters }} characters"></ui-checkbox>
</div>

<div
    class="row"
    *ngIf="checklist.hasOwnProperty('hasNumber')">
    <ui-checkbox
        [disabled]="true"
        [selected]="checklist.hasNumber"
        label="At least 1 number"></ui-checkbox>
</div>

<div
    class="row"
    *ngIf="
        passwordRequirements.requireSpecialCharacter && checklist.hasOwnProperty('hasSpecialCharacter')
    ">
    <ui-checkbox
        [disabled]="true"
        [selected]="checklist.hasSpecialCharacter"
        label="At least 1 special character"></ui-checkbox>
</div>

<div
    class="row"
    *ngIf="checklist.hasOwnProperty('hasLowerAndUppercase')">
    <ui-checkbox
        [disabled]="true"
        [selected]="checklist.hasLowerAndUppercase"
        label="Lower and uppercase"></ui-checkbox>
</div>

<div
    class="row"
    *ngIf="checklist.hasOwnProperty('passwordsMatching')">
    <ui-checkbox
        [disabled]="true"
        [selected]="checklist.passwordsMatching"
        label="Passwords match"></ui-checkbox>
</div>
