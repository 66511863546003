import { Environment, PasswordConnectionHint } from './environment.type';

export const environment: Environment = {
    LOGIN_URL: 'https://login.bannerflow.com',
    CLIENT_ID: 'b3tatgviNxjBh8jUMlbYOIJvMUkDmTL3',
    BANNEFLOW_CLIENT_URL: 'https://home.bannerflow.com',
    ACCOUNT_ACCESS_SERVICE_URL: 'https://api.bannerflow.com/account-access',
    BAU_SERVICE_URL: 'https://api.bannerflow.com/bau',
    CONNECTION_HINT_MAP: new Map<string, string>([
        [PasswordConnectionHint, 'Username-Password-Authentication'],
        ['bfg', 'bannerflow-google'],
        ['bfe', 'bannerflow-entra'],
        ['accenturejlr', 'accenture-jlr-saml'],
        ['accenturel', 'accenture-entra'],
        ['sok', 'sok-entra'],
        ['if', 'if-saml']
    ])
};
