import { Component } from '@angular/core';

import { UIModule } from '@bannerflow/ui';

@Component({
    selector: 'app-invitation-expired',
    templateUrl: './invitation-expired.component.html',
    styleUrls: ['./invitation-expired.component.scss'],
    imports: [UIModule]
})
export class InvitationExpiredComponent {}
