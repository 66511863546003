<div>
    <title>Test SSO Connection</title>
    <div
        class="content"
        *ngIf="this.success">
        <div class="logo">
            <ui-logo [small]="true"></ui-logo>
        </div>
        <div class="title">
            <h1>SSO Connection Works</h1>
        </div>
        <div class="text">
            <p>The tested SSO connection works with the Bannerflow platform!</p>
        </div>
    </div>
</div>
