import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { UIModule } from '@bannerflow/ui';

import { LoginComponent } from './components';
import { LoginService } from './services';

@NgModule({
    imports: [LoginComponent, CommonModule, RouterModule, UIModule],
    exports: [LoginComponent],
    providers: [LoginService]
})
export class LoginModule {}
