import { ValidatorFn, FormControl, ValidationErrors, AbstractControl } from '@angular/forms';

/** this control value must be equal to given control's value */
export function MatchValidator(a: string, b: string): ValidatorFn {
    return (g: AbstractControl): Record<string, MatchResult | null> => {
        return g.get(a).value === g.get(b).value ? null : { result: { mismatch: true } };
    };
}

export function matchOtherValidator(otherControlName: string): ValidatorFn {
    let thisControl: FormControl;
    let otherControl: FormControl;

    return function matchOtherValidate(control: AbstractControl): ValidationErrors | null {
        if (!control.parent) {
            return null;
        }

        if (!thisControl) {
            thisControl = control as FormControl;
            otherControl = control.parent.get(otherControlName) as FormControl;
            if (otherControl) {
                otherControl.valueChanges.subscribe(() => {
                    thisControl.updateValueAndValidity();
                });
            }
        }

        if (!otherControl) {
            return null;
        }

        if (otherControl.value !== thisControl.value) {
            return {
                mismatch: true
            };
        }

        return null;
    };
}

export interface MatchResult {
    mismatch: boolean;
}
