import { inject, Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { PasswordConnectionHint } from 'src/environments/environment.type';

@Injectable()
export class LoginService {
    private auth: AuthService = inject(AuthService);

    public loginWithConnectionHint(connectionHint: string): void {
        const mappedConnectionHint: string =
            environment.CONNECTION_HINT_MAP.get(connectionHint) ??
            environment.CONNECTION_HINT_MAP.get(PasswordConnectionHint);

        this.auth.loginWithRedirect({
            authorizationParams: {
                connection: mappedConnectionHint,
                redirect_uri: environment.BANNEFLOW_CLIENT_URL
            }
        });
    }

    public getTokenWithConnectionHint(connectionHint: string): Observable<string | undefined> {
        const mappedConnectionHint: string =
            environment.CONNECTION_HINT_MAP.get(connectionHint) ?? undefined;

        return this.auth.getAccessTokenWithPopup({
            authorizationParams: {
                connection: mappedConnectionHint,
                audience: 'https://bannerflow.com/resources/',
                scope: 'openid bannerflow'
            }
        });
    }
}
