import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';

import { UserTitle } from '../models';

@Injectable({
    providedIn: 'root'
})
export class ProfileService {
    public baseUrl = `${environment.ACCOUNT_ACCESS_SERVICE_URL}/api`;
    private http: HttpClient = inject(HttpClient);

    public getUserTitles(): Promise<UserTitle[]> {
        const url = `${this.baseUrl}/user-titles`;
        return this.http.get<UserTitle[]>(url).toPromise();
    }
}
