import { Injectable } from '@angular/core';

import { TimeZone } from '../models';

import { timeZones } from './time-zones';

@Injectable()
export class TimeZoneService {
    public getTimeZones(): TimeZone[] {
        return timeZones;
    }
}
