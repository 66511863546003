<div class="content">
    <title>Invitation Expired</title>
    <div class="logo">
        <ui-logo [small]="true"></ui-logo>
    </div>
    <div class="title">
        <h1>Invitation Expired</h1>
    </div>
    <div class="text">
        <p>User invitation has expired. Ask the account administrator(s) to resend invitation.</p>
    </div>
</div>
