import { Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { LoginComponent, LoginTestComponent } from './login/components';
import { OopsComponent } from './shared/components';
import { InvitationExpiredComponent, RegistrationComponent } from './registration/components';

export const routes: Routes = [
    {
        path: '',
        component: AppComponent
    },
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'login/test',
        component: LoginTestComponent
    },
    {
        path: 'oops',
        component: OopsComponent
    },
    {
        path: 'register',
        component: RegistrationComponent
    },
    {
        path: 'invitation-expired',
        component: InvitationExpiredComponent
    }
];
