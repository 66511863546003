import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { LoginService } from '../../services';

@Component({
    selector: 'app-login',
    template: '',
    styles: [''],
    standalone: true
})
export class LoginComponent implements OnInit {
    private activatedRoute: ActivatedRoute = inject(ActivatedRoute);
    private loginService: LoginService = inject(LoginService);

    ngOnInit(): void {
        const connectionHint: string = this.activatedRoute.snapshot.queryParams.ch ?? '';
        this.loginService.loginWithConnectionHint(connectionHint);
    }
}
