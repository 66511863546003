import { provideHttpClient } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';
import { provideAuth0 } from '@auth0/auth0-angular';

import { UIModule } from '@bannerflow/ui';
import { environment } from 'src/environments/environment';

import { routes } from './app.routes';
import { RegistrationModule } from './registration/registration.module';
import { SharedModule } from './shared/shared.module';
import { LoginModule } from './login/login.module';

export const appConfig: ApplicationConfig = {
    providers: [
        importProvidersFrom(RegistrationModule, SharedModule, UIModule, LoginModule),
        provideZoneChangeDetection({ eventCoalescing: true }),
        provideRouter(routes),
        provideAuth0({
            domain: environment.LOGIN_URL,
            clientId: environment.CLIENT_ID
        }),
        provideHttpClient()
    ]
};
