import { Serializable } from 'src/app/shared/interfaces';

export class NewUser implements Serializable<NewUser> {
    public userId: string;
    public givenName: string;
    public familyName: string;
    public email: string;
    public password: string;
    public passwordConfirmation: string;
    public timeZone: string;
    public title: string;
    public invitationId: string;
    public acceptsToC = false;

    public deserialize(json: NewUser): NewUser {
        if (!json) {
            return this;
        }

        this.userId = json.userId;
        this.invitationId = json.invitationId;
        this.givenName = json.givenName;
        this.familyName = json.familyName;
        this.email = json.email;
        this.timeZone = json.timeZone;
        this.password = json.password;
        this.passwordConfirmation = json.passwordConfirmation;
        this.acceptsToC = json.acceptsToC;
        this.title = json.title;

        return this;
    }
}
